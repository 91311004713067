import { defineAsyncComponent } from 'vue'
import Multiselect from 'vue-multiselect'
import RelativeTime from '../../components/common/relative-time.vue'
import ImportModuleError from '../../components/common/import-module-error.vue'

// wrap with handling dynamic imports error
// https://sentry.io/answers/failed-to-fetch-dynamically-imported-module-in-react/
function defineAsyncComponentWrapper (loader) {
  async function importFn () {
    try {
      return await loader()
    } catch (error) {
      if (
        error.name === 'ChunkLoadError' ||
        error.message.includes('Failed to fetch dynamically imported module')
      ) {
        return ImportModuleError
      }

      return Promise.reject(error)
    }
  }

  return defineAsyncComponent(() => importFn())
}

export function setupGlobalComponents (app) {
  app.component('Multiselect', Multiselect)
  app.component('RelativeTime', RelativeTime)

  app.component('AuthSocialNav', defineAsyncComponentWrapper(() => import('../../components/auth/social-nav.vue')))
  app.component('AuthRegister', defineAsyncComponentWrapper(() => import('../../components/auth/register/register.vue')))
  app.component('MessageBubble', defineAsyncComponentWrapper(() => import('../../components/message-bubble.vue')))

  app.component('VideoShow', defineAsyncComponentWrapper(() => import('../../components/video/show.vue')))
  app.component('VideoShowReply', defineAsyncComponentWrapper(() => import('../../components/video/show-reply.vue')))
  app.component('VideoAppShare', defineAsyncComponentWrapper(() => import('../../components/video/app-share.vue')))
  app.component('VideoEmbed', defineAsyncComponentWrapper(() => import('../../components/video/embed.vue')))
  app.component('VideoEmbedCreateWrapper', defineAsyncComponentWrapper(() => import('../../components/video/embed-create-wrapper.vue')))
  app.component('VideoCreateWithPermissionModal', defineAsyncComponentWrapper(() => import('../../components/video/create-with-permission-modal.vue')))
  app.component('VideoPasswordForm', defineAsyncComponentWrapper(() => import('../../components/video/password-form.vue')))
  app.component('VideoPortfolio', defineAsyncComponentWrapper(() => import('../../components/video/portfolio.vue')))

  app.component('ShowcaseShow', defineAsyncComponentWrapper(() => import('../../components/showcase/show.vue')))
  app.component('ShowcaseEmbed', defineAsyncComponentWrapper(() => import('../../components/showcase/embed.vue')))

  app.component('FormRender', defineAsyncComponentWrapper(() => import('../../components/form/render.vue')))
  app.component('BootModal', defineAsyncComponentWrapper(() => import('../../components/common/boot-modal.vue')))
  app.component('ForceRouterLink', defineAsyncComponentWrapper(() => import('../../components/common/force-router-link.vue')))
  app.component('VideoJs', defineAsyncComponentWrapper(() => import('../../components/common/video-js.vue')))
  app.component('VideoPreviewList', defineAsyncComponentWrapper(() => import('../../components/common/video-preview-list.vue')))
  app.component('WorldMapJoin', defineAsyncComponentWrapper(() => import('../../components/common/world-map-join.vue')))
  app.component('FeatureLocker', defineAsyncComponentWrapper(() => import('../../components/common/feature-locker.vue')))
  app.component('ExitIntent', defineAsyncComponentWrapper(() => import('../../components/common/exit-intent.vue')))
  app.component('EmptyTableMessage', defineAsyncComponentWrapper(() => import('../../components/common/empty-table-message.vue')))
  app.component('Paginator', defineAsyncComponentWrapper(() => import('../../components/common/paginator.vue')))
  app.component('V4Navbar', defineAsyncComponentWrapper(() => import('../../components/common/v4-navbar.vue')))
  app.component('RichTooltip', defineAsyncComponentWrapper(() => import('../../components/rich-tooltip.vue')))

  app.component('LandingPageBuilderGrapesjs', defineAsyncComponentWrapper(() => import('../../components/landing-page/grapesjs-builder.vue')))
  app.component('LandingPageBuilderUnlayer', defineAsyncComponentWrapper(() => import('../../components/landing-page/unlayer-builder.vue')))

  app.component('GuestVideoCreate', defineAsyncComponentWrapper(() => import('../../components/guest-video/create.vue')))
  app.component('GuestVideoShow', defineAsyncComponentWrapper(() => import('../../components/guest-video/show.vue')))
  app.component('GuestVideoEdit', defineAsyncComponentWrapper(() => import('../../components/guest-video/edit.vue')))

  app.component('EmailTemplateBuilderGrapesjs', defineAsyncComponentWrapper(() => import('../../components/email-template/grapesjs-builder.vue')))
  app.component('EmailTemplateBuilderUnlayer', defineAsyncComponentWrapper(() => import('../../components/email-template/unlayer-builder.vue')))

  app.component('VideoTemplateBuilder', defineAsyncComponentWrapper(() => import('../../components/video-template/builder.vue')))
  app.component('VideoSection', defineAsyncComponentWrapper(() => import('../../components/video-template/video-section.vue')))
  app.component('VideoTitle', defineAsyncComponentWrapper(() => import('../../components/video-template/video-title.vue')))
  app.component('VideoDescription', defineAsyncComponentWrapper(() => import('../../components/video-template/video-description.vue')))
  app.component('CtaSection', defineAsyncComponentWrapper(() => import('../../components/video-template/cta-section.vue')))
  app.component('ContactSection', defineAsyncComponentWrapper(() => import('../../components/video-template/contact-section.vue')))
  app.component('FormSection', defineAsyncComponentWrapper(() => import('../../components/video-template/form-section.vue')))
  app.component('CalendarSection', defineAsyncComponentWrapper(() => import('../../components/video-template/calendar-section.vue')))

  app.component('TeamSubscription', defineAsyncComponentWrapper(() => import('../../components/team-subscription/index.vue')))

  app.component('PagesMarketplace', defineAsyncComponentWrapper(() => import('../../components/pages/marketplace.vue')))
}
