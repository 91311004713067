export default [{
  path: '/spark/kiosk',
  components: {
    default: () => import('../modules/kiosk/empty.vue'),
    aside: () => import('../modules/kiosk/sub-views/aside.vue')
  },
  children: [
    {
      path: 'activity-report',
      name: 'kiosk.activity-report',
      component: () => import('../modules/kiosk/activity-report.vue')
    }, {
      path: 'metrics',
      name: 'kiosk.metrics',
      component: () => import('../modules/kiosk/metrics.vue')
    }, {
      path: 'kiosk-users',
      name: 'kiosk.kiosk-users',
      component: () => import('../modules/kiosk/kiosk-users.vue')
    }, {
      path: 'users',
      name: 'kiosk.users',
      component: () => import('../modules/kiosk/users/index.vue')
    }, {
      path: 'subscribers',
      name: 'kiosk.subscribers',
      component: () => import('../modules/kiosk/subscribers.vue')
    }, {
      path: 'mobile-subscribers',
      name: 'kiosk.mobile-subscribers',
      component: () => import('../modules/kiosk/mobile-subscribers.vue')
    }, {
      path: 'cancellations',
      name: 'kiosk.cancellations',
      component: () => import('../modules/kiosk/cancellations.vue')
    }, {
      path: 'users/:id',
      name: 'kiosk.users.show',
      component: () => import('../modules/kiosk/users/show.vue')
    }, {
      path: 'leads',
      name: 'kiosk.leads',
      component: () => import('../modules/kiosk/leads.vue')
    }, {
      path: 'redirections',
      name: 'kiosk.redirections',
      component: () => import('../modules/kiosk/redirections.vue')
    }, {
      path: 'cdn',
      name: 'kiosk.cdn',
      component: () => import('../modules/kiosk/cdn.vue')
    }, {
      path: 'teams',
      name: 'kiosk.teams',
      component: () => import('../modules/kiosk/teams/index.vue')
    }, {
      path: 'teams/:id',
      name: 'kiosk.teams.show',
      component: () => import('../modules/kiosk/teams/show.vue')
    }, {
      path: 'team_sso_configurations',
      name: 'kiosk.team_sso_configurations',
      component: () => import('../modules/kiosk/team-sso-configurations/list.vue')
    }, {
      path: 'announcements',
      name: 'kiosk.announcements',
      component: () => import('../modules/kiosk/announcements/index.vue')
    }, {
      path: 'announcements/create',
      name: 'kiosk.announcements.create',
      component: () => import('../modules/kiosk/announcements/edit.vue')
    }, {
      path: 'announcements/:id',
      name: 'kiosk.announcements.show',
      component: () => import('../modules/kiosk/announcements/edit.vue')
    }, {
      path: 'settings',
      name: 'kiosk.settings',
      component: () => import('../modules/kiosk/settings.vue')
    }, {
      path: 'markdowns',
      name: 'kiosk.markdowns',
      component: () => import('../modules/kiosk/markdowns.vue')
    }, {
      path: 'video-analysis-settings',
      name: 'kiosk.video-analysis-settings',
      component: () => import('../modules/kiosk/video-analysis-settings.vue')
    }, {
      path: 'partners',
      name: 'kiosk.partners',
      component: () => import('../modules/kiosk/dubb-objects/partners.vue')
    }, {
      path: 'coaches',
      name: 'kiosk.coaches',
      component: () => import('../modules/kiosk/dubb-objects/coaches.vue')
    }, {
      path: 'integrations',
      name: 'kiosk.integrations',
      component: () => import('../modules/kiosk/dubb-objects/integrations.vue')
    }, {
      path: 'optimize-for',
      name: 'kiosk.optimize-for',
      component: () => import('../modules/kiosk/dubb-objects/optimize-for.vue')
    }, {
      path: 'dashboard-messages',
      name: 'kiosk.dashboard-messages',
      component: () => import('../modules/kiosk/dubb-objects/dashboard-messages.vue')
    }, {
      path: 'crm-options',
      name: 'kiosk.crm-options',
      component: () => import('../modules/kiosk/dubb-objects/crm-options.vue')
    }, {
      path: 'partner-promo-code',
      name: 'kiosk.partner-promo-code',
      component: () => import('../modules/kiosk/partner-promo-code.vue')
    }, {
      path: 'email-templates',
      name: 'kiosk.email-templates.index',
      component: () => import('../modules/kiosk/templates/email-templates/index.vue')
    }, {
      path: 'email-templates/create',
      name: 'kiosk.email-templates.create',
      component: () => import('../modules/kiosk/templates/email-templates/create_or_update.vue')
    }, {
      path: 'email-templates/edit/:hash_id',
      name: 'kiosk.email-templates.edit',
      component: () => import('../modules/kiosk/templates/email-templates/create_or_update.vue')
    }, {
      path: 'video-templates',
      name: 'kiosk.video-templates.index',
      component: () => import('../modules/kiosk/templates/video-templates/index.vue')
    }, {
      path: 'video-templates/create',
      name: 'kiosk.video-templates.create',
      component: () => import('../modules/kiosk/templates/video-templates/form.vue')
    }, {
      path: 'video-templates/edit/:hash_id',
      name: 'kiosk.video-templates.edit',
      component: () => import('../modules/kiosk/templates/video-templates/form.vue')
    }, {
      path: 'landing-page-templates',
      name: 'kiosk.landing-page-templates.index',
      component: () => import('../modules/kiosk/templates/landing-page-templates/index.vue')
    }, {
      path: 'pages',
      name: 'kiosk.pages.index',
      component: () => import('../modules/kiosk/pages/index.vue')
    }, {
      path: 'pages/settings',
      name: 'kiosk.pages.settings',
      component: () => import('../modules/kiosk/pages/settings.vue')
    }, {
      path: 'pages/create',
      name: 'kiosk.pages.create',
      component: () => import('../modules/kiosk/pages/edit.vue')
    }, {
      path: 'pages/edit/:id',
      name: 'kiosk.pages.edit',
      component: () => import('../modules/kiosk/pages/edit.vue')
    }, {
      path: 'pages/clone/:id',
      name: 'kiosk.pages.clone',
      component: () => import('../modules/kiosk/pages/edit.vue')
    }, {
      path: 'pages/elements',
      name: 'kiosk.pages.elements',
      component: () => import('../modules/kiosk/pages/elements/index.vue')
    }, {
      path: 'pages/elements/create',
      name: 'kiosk.pages.elements.create',
      component: () => import('../modules/kiosk/pages/elements/edit.vue')
    }, {
      path: 'pages/elements/edit/:id',
      name: 'kiosk.pages.elements.edit',
      component: () => import('../modules/kiosk/pages/elements/edit.vue')
    }, {
      path: 'pages/elements/clone/:id',
      name: 'kiosk.pages.elements.clone',
      component: () => import('../modules/kiosk/pages/elements/edit.vue')
    }, {
      path: 'mrss',
      name: 'kiosk.mrss',
      component: () => import('../modules/kiosk/dubb-objects/mrss.vue')
    }, {
      path: 'faqs',
      name: 'kiosk.faqs',
      component: () => import('../modules/kiosk/dubb-objects/faqs.vue')
    }, {
      path: 'lead-emails',
      name: 'kiosk.lead-emails.index',
      component: () => import('../modules/kiosk/dubb-objects/lead-emails/index.vue')
    }, {
      path: 'lead-emails/:type/create',
      name: 'kiosk.lead-emails.create',
      component: () => import('../modules/kiosk/dubb-objects/lead-emails/edit.vue')
    }, {
      path: 'lead-emails/:type/edit/:id',
      name: 'kiosk.lead-emails.edit',
      component: () => import('../modules/kiosk/dubb-objects/lead-emails/edit.vue')
    }
  ]
}]
