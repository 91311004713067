<script setup>
function refreshPage () {
  window.location.reload()
}
</script>

<template>
  <div class="alert alert-light font-size-xs h-50px">
    Failed to load component.
    <a
      class="alert-link"
      role="button"
      @click="refreshPage"
    >
      Refresh page
    </a>
  </div>
</template>
