import constants from '@/generated/constants.js'

// Do not set route name to standalone routes
export default [
  {
    path: '/subdomain',
    component: () => import('../modules/standalones/subdomain.vue'),
    meta: {
      title: 'Create Team'
    }
  },
  {
    path: '/verify-phone',
    component: () => import('../modules/standalones/verify-phone.vue'),
    meta: {
      title: 'Verify your Identity'
    }
  },
  {
    path: '/invite-team-members',
    component: () => import('../modules/standalones/invite-team-members.vue'),
    meta: {
      title: 'Invite others to your team'
    }
  },
  {
    path: '/redeem-partner-code',
    component: () => import('../modules/standalones/redeem-partner-code.vue')
  },
  {
    path: '/tools/convertor',
    component: () => import('../modules/standalones/tools/convertor.vue'),
    meta: {
      title: 'Video Converter'
    }
  },
  {
    path: '/messaging',
    name: 'standalones.messaging',
    component: () => import('../modules/standalones/messaging.vue'),
    meta: {
      title: 'Messaging',
      rolePermission: constants.App_Models_TeamRole.PERMISSION_MESSAGING
    },
    children: [
      {
        path: 'online',
        name: 'standalones.messaging.online',
        component: () => import('../modules/standalones/messaging/online.vue'),
        meta: {
          messagingTab: 'online'
        }
      },
      {
        path: 'sms',
        name: 'standalones.messaging.sms',
        component: () => import('../modules/standalones/messaging/sms.vue'),
        meta: {
          messagingTab: 'sms'
        }
      }
    ]
  },
  {
    path: '/campaign/:slug/info',
    component: () => import('../modules/dashboard-v5/campaigns/info.vue'),
    meta: {
      title: 'Campaign Details'
    }
  },
  {
    path: '/sia/:hash_id',
    component: () => import('../modules/standalones/sia.vue')
  },
  {
    path: '/mobile-captcha',
    component: () => import('../modules/standalones/mobile-captcha.vue')
  },
  {
    path: '/v/:slug/edit',
    components: {
      default: () => import('../components/video/edit.vue'),
      aside: () => import('../modules/dashboard-v5/sub-views/aside.vue')
    },
    meta: {
      group: 'video-edit',
      'aside-minimized': true
    }
  },
  {
    path: '/avatar-video/:hash_id',
    name: 'standalones.avatar-video.show',
    component: () => import('../modules/standalones/avatar-video/avatar-video.vue'),
    meta: {
      title: 'Avatar Video'
    }
  }
]
